import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 24" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 24 - Julaften!</h1>
      <p>Min første julaften - jippiiii!</p>
      <p>
        Jøss, her kan det se ut som om nissen har vært i natt... Han kunne da ryddet litt opp etter seg, men han hadde det sikkert travelt, tihiihiihi! Det var ikke meg, jeg lover!
      </p>
      <p>
        En gang for lenge siden, da jeg lå inni magen din, husker jeg at du var
        på leting etter sånn sterkt pulver i butikken. Jeg lovet meg selv at jeg skulle
        finne det for deg en dag, og hvilken bedre dag enn julaften finnes for å
        gi deg det som gave. Sjekk inni sminkeveska om du ikke har funnet det
        allerede.
      </p>
      <p>
        Jeg elsker deg mamma, hilsen Litjen! Pappa elsker deg også! I dag blir en fin dag.
      </p>
      <p>Gooood juuuul!</p>
      <p>Hilsen Litjen</p>
      <p style={{marginTop: '130px'}}>Okei da, jeg klarer ikke holde meg mer, det var jeg som var nissen i natt! Her ser du et bilde av meg etter at jeg hadde ordnet siste luka i en Lijen adventskalender til deg.</p>
      <LitjenImage />
      <p>Ho ho ho ho! (slik som nissen sier)</p>
    </div>
  </Layout>
)


const LitjenImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        litjen3: file(relativePath: { eq: "litjen3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (<div>
      <Img fluid={data.litjen3.childImageSharp.fluid} />
    </div>)}
  />
)

export default LitjenLuke
